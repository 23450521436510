<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="8" :md="12">
                  <el-form-item label-width="120px" label="服务商申请">
                    <el-input
                      v-model="where.name"
                      placeholder="请输入服务商申请"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12" style="text-align: center">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:status="{ scope }">
                <span v-if="scope.row.status == 1">已开通</span>
                <span v-else>未开通</span>
              </template>
              <template v-slot:zhanwei="{ scope }">
                <span v-if="scope.row.status == 1">无</span>
                <a v-else @click="liberal(scope.row)" v-auths="[
                    `${$config.uniquePrefix}operate:application:status`,
                  ]">开通</a>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

//引入分配权限组件
// import Assignpermissions from './assignpermissions.vue'

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import { experience, openapplication } from "@/api/operate";
import dayjs from "dayjs";
export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
    // Assignpermissions
  },
  // Data数据
  data() {
    return {
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "用户id",
          prop: "id",
          isShow: true,
        },
        {
          label: "申请服务商",
          prop: "facilitator_id",
          isShow: true,
        },
        {
          label: "申请时间",
          prop: "apple_time",
          isShow: true,
        },
        {
           label: "到期时间",
           prop: "end_time",
           isShow: true,
        },
        {
          label: "是否开通",
          prop: "status",
          slot: "status",
          isShow: true,
        },
        {
          label: "操作",
          prop: "zhanwei",
          slot: "zhanwei",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,

      // 表单设计
      FormShow: false,

      // 分配权限
      drawer: false,
      direction: "rtl",
      showEdit: false,
      current: null,

      // 表格数据
      List1: [],
      //加载动画
      loading1: true,
      // 表头数据

      //唯一标识
    };
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  destroyed() {
    console.log("刷新");
    this.getList();
  },

  // 方法集合
  methods: {
    init_getList() {
      //初始获取数据
    },
    init_del_list_l() {
      //删除数据中的某一个
    },
    getList() {
      experience(this.where)
        .then(async (res) => {
          console.log("申请记录", res);
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },
    // 查询条件
    QueryList() {
      this.getList();
    },
    liberal(row) {
      const currentDate = dayjs(); // 获取当前日期
      const nextDate = currentDate.add(1, "day");
      let data = {
        start_time: currentDate.format("YYYY-MM-DD"),
        end_time: nextDate.format("YYYY-MM-DD"),
      };
      openapplication(row.id, data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false;
      } else {
        return true;
      }
    },

    // 删除
    del(row) {
      console.log(row);
      this.$utils.confirm(
        "确定要删除吗？",
        function () {
          // 执行一些需要的逻辑
          console.log("点击确定");
        },
        "确定",
        "warning"
      );
    },

    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose() {
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value) {
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value) {
      this.ListSetupShow = value;
    },

    // 点击设计表单
    FormDesign(row) {
      console.log("设计表单");
      // this.FormShow = true;
      // this.$router.push('/FormParser')
      let pathHerf = this.$router.resolve({
        path: "/FormParser", // 你自己要跳转的路径
        query: {
          uniques: row.uniques, // query 要传递携带的参数，如：当前传递的是所需id
        },
      });
      window.open(pathHerf.href, "_blank");
      // window.open('/FormParser')
    },

    // 抽屉关闭
    drawerhandleClose() {
      this.drawer = false;
      this.getList();
    },
    //查看组件
    allocation(row) {
      // this.showEdit = true;
      // this.current = row;
      // console.log(row.uniques)
      this.uniques = row.uniques;
      // 获取抽屉数据
      this.getList1(row.uniques);
      // 显示抽屉
      this.drawer = true;
    },
    //获取抽屉数据
    getList1(uniques) {
      form_assistlist(uniques).then((res) => {
        console.log(res);
        this.List1 = res.data.list;
        this.loading1 = false;
      });
    },

    //点击设置权限
    setPermission(row) {
      this.current = row;
      this.showEdit = true;
    },

    // 分配权限弹窗传递的事件
    queryDone() {
      // 获取表格数据
      this.getList();
      // 获取分配权限表格数据
      this.getList1(this.uniques);
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
</style>
